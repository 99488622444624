require("@rails/ujs").start();
require("turbolinks").start();
require("channels");
require("jquery");
require("sleek-dashboard/dist/assets/js/sleek.bundle");
require("jquery-slimscroll");
require("simplebar");
require("moment");

import moment from "moment";

window.moment = moment;
window.NProgress = require("sleek-dashboard/dist/assets/plugins/nprogress/nprogress");
